import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const PropertyServices = (props) => {
  return (
    <section className="property-services">
      <Container>
        <Row>
          <Col lg={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.primary_title && <h6>{props?.primary_title}</h6>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.secondary_title && <h2>{props?.secondary_title}</h2>}
            </ScrollAnimation>
          </Col>
        </Row>
        <div className="card-wrapper">
        
          {props?.services?.map((item, i) => {
            const imagename = "page.property_services_image.add_image"

            let processedImages = JSON.stringify({})
            if (props?.imagetransforms?.property_services_image_Transforms) {
              processedImages =
                props?.imagetransforms?.property_services_image_Transforms
            }
            return (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOnce
                delay={(i + 1) * 200}
                offset={100}
                className="card"
              >
                  <CTALink
                      class="card-item"
                      link={item?.service_link?.cta_link}
                      title={item?.service_link?.cta_label}
                      target_window={item?.service_link?.link_type}
                    >
                <ImageModule
                  ImageSrc={item?.add_image}
                  altText={item?.add_image?.alternativeText}
                  imagetransforms={processedImages}
                  renderer="srcSet"
                  imagename={imagename}
                  strapi_id={props?.id}
                  classNames="img-card"
                />
                <h6>{item.heading}</h6>
                <ContentModule Content={item.description?.data?.description} />
                {/* {item?.service_link?.cta_link &&
                  item?.service_link.cta_label && (
                    <CTALink
                      class="button button-arrow"
                      link={item?.service_link?.cta_link}
                      title={item?.service_link.cta_label}
                      target_window={item?.service_link.link_type}
                    />
                  )} */}
                  </CTALink>
              </ScrollAnimation>
            )
          })}        </div>
      </Container>
      
    </section>
  )
}

export default PropertyServices
