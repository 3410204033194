import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import dateFormat from "dateformat"
import { CustomSlider } from "../CustomSlider/CustomSlider"
import "./NewsSliderCard1.scss"
import { newsUrl } from "../../lib/urls"

const NewsSliderCard1 = ({ blogData,pageData }) => {
  if (!blogData?.length) return
  
  return (
    <div className="news-slider-wrapper">
      <Container>
        <div className="news-slider-module">
          <h2>{pageData?.title}</h2>
          <CustomSlider className="news-slider" slidecount={3}>
            {blogData?.map((item) => {
              return (
                <div className="slider-card" key={item?.node?.slug}>
                  <div className="image-section">
                    <Link
                      className="title"
                      to={`${newsUrl}${item?.node?.slug}`}
                    >
                      <img src={item?.node?.tile_image?.url} alt="pop" />
                    </Link>
                  </div>
                  <div className="content-section">
                    <Link
                      className="title"
                      to={`${newsUrl}${item?.node?.slug}`}
                    >
                      {item?.node?.title}
                    </Link>
                    <p className="date">
                      {dateFormat(item?.node?.date, "dd mmmm yy")}
                    </p>
                  </div>
                </div>
              )
            })}
          </CustomSlider>
        </div>
      </Container>
    </div>
  )
}

export default NewsSliderCard1
