import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import BannerSearch from "../BannerSearch/BannerSearch"
import "./assets/styles/_indexhome.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import useInteractive from "../../hooks/useInteractive"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const Banner = (props) => {
  const imagename = "page.banner_section_banner_image.landing_banner_image"
  let processedImages = JSON.stringify({})
  const { hasInteracted } = useInteractive()
  const { isMobile } = useDeviceMedia()
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms?.banner_section_banner_image_Transforms
  }

  return (
    <section className="banner d-xl-flex align-items-center home-banner">
      {/* <HomeBannerImage /> */}
      {(hasInteracted || isMobile == false) &&
        <ImageModule
          ImageSrc={props?.image}
          altText={`${
            props?.image?.alternativeText
              ? props?.image?.alternativeText
              : props.banner_title
              ? props.banner_title
              : props.title
          } banner`}
          imagetransforms={processedImages}
          renderer="bgImg"
          imagename={imagename}
          strapi_id={props?.id}
          classNames="img-fluid banner-img"
        />
      }
      <div className="overlay-bg" />
      <Container className="banner-search-container">
        <Row>
          <Col xl={6}>
            {props?.banner_title && (
              <h1>
                <ContentModule Content={props?.banner_title} />
              </h1>
            )}
            {props?.show_search && <BannerSearch />}
            {props?.banner_content && (
              <ContentModule
                Content={props?.banner_content?.data?.banner_content}
              />
            )}
            <ul className="list-inline button-list">
              {props?.cta_1_title && (props?.cta_1_link || props?.cta1_custom) && (
                <li className="list-inline-item">
                  <CTALink
                    class="button button-secondary-outline"
                    link={props?.cta_1_link ? props?.cta_1_link:{ external_link: props?.cta1_custom}}
                    title={props?.cta_1_title}
                    target_window={props?.cta_1_link?.target_window}
                  />
                </li>
              )}
              {props?.cta_2_title && (props?.cta_2_link || props?.cta2_custom) && (
                <li className="list-inline-item">
                  <CTALink
                    class="button button-secondary-outline"
                    link={props?.cta_2_link? props?.cta_2_link:{ external_link: props?.cta2_custom}}
                    title={props?.cta_2_title}
                    target_window={props?.cta_2_link?.target_window}
                  />
                </li>
              )}
              {props?.cta_3_title && (props?.cta_3_link || props?.cta3_custom) && (
                <li className="list-inline-item">
                  <CTALink
                    class="button button-secondary-outline"
                    link={props?.cta_3_link? props?.cta_3_link:{ external_link: props?.cta3_custom}}
                    title={props?.cta_3_title}
                    target_window={props?.cta_3_link?.target_window}
                  />
                </li>
              )}
              {props?.cta_4_title && (props?.cta_4_link || props?.cta4_custom) && (
                <li className="list-inline-item">
                  <CTALink
                    class="button button-secondary-outline"
                    link={props?.cta_4_link? props?.cta_4_link:{ external_link: props?.cta4_custom}}
                    title={props?.cta_4_title}
                    target_window={props?.cta_4_link?.target_window}
                  />
                </li>
              )}
              {props?.cta_5_title && (props?.cta_5_link || props?.cta5_custom) && (
                <li className="list-inline-item">
                  <CTALink
                    class="button button-secondary-outline"
                    link={props?.cta_5_link? props?.cta_5_link:{ external_link: props?.cta5_custom}}
                    title={props?.cta_5_title}
                    target_window={props?.cta_5_link?.target_window}
                  />
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
