import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import ReviewImg from "../images/review.png";
import Banner from "../components/Banner/HomeBanner"
import NewsSliderCard1 from "../components/NewsSliderCard1/NewsSliderCard1"
import PopularCommunity from "../components/PopularCommunity/PopularCommunity"
import PropertyServices from "../components/PropertyServices/PropertyServices"
import ShowcaseComponent from "../components/ShowcaseComponent/ShowcaseComponent"
// import TileBlockReview from "../components/TileBlockReview/TileBlockReview";

const FeaturedProperties = loadable(() =>
  import("../components/FeaturedProperties/FeaturedProperties")
)
const IntroModule = loadable(() =>
  import("../components/IntroModule/IntroModule")
)
// const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const TileBlockRight = loadable(() =>
  import("../components/TileBlockRight/TileBlockRight")
)
// const FeaturedSlider = loadable(() => import("../components/FeaturedSlider/FeaturedSlider"));

const HomePageTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const blogData =
    data?.allStrapiBlog?.edges?.length > 0 ? data?.allStrapiBlog?.edges : ""
  const _siteData = data?.strapiSiteConfig
  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("scroll", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  })

  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>
      {/* {JSON.stringify(PageData)} */}
      {PageData.banner && (
        <Banner
          title={PageData.title}
          {...PageData.banner}
          id={PageData.strapi_id}
          imagetransforms={PageData?.imagetransforms}
        />
      )}

      {PageData?.add_page_modules?.length > 0 &&
        PageData?.add_page_modules?.map((module, index) => {
          return (
            <div key={[index]}>
              {/* {module.strapi_component === "page-modules.text-module" && <PatternBanner
                            tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                            {...module}
                        />} */}
              {module.strapi_component === "page-modules.stat-with-content" && (
                <IntroModule
                  tag="landing"
                  {...module}
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                />
              )}
              {module.strapi_component === "components.market-banner" && (
                <ShowcaseComponent
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                  data={module}
                  title={PageData?.banner?.banner_title}
                  guideLink={PageData?.banner?.guide_link?.url}
                />
              )}
              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "left" && (
                  <TileBlock
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )}

              {module.strapi_component === "page-modules.image-and-content" &&
                module.image_alignment === "right" && (
                  <TileBlockRight
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    data={PageData}
                  />
                )}

              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "featured_properties" &&
                renderComponent && <FeaturedProperties {...module} />}

              {module?.strapi_component === "page-modules.property-services" &&
                renderComponent && (
                  <PropertyServices
                    {...module}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )}
              {module?.strapi_component === "components.community" &&
                renderComponent && <PopularCommunity {...module} />}

              {module?.strapi_component === "page-modules.global-module" &&
                module?.select_module === "blog" &&
                renderComponent && (
                  <NewsSliderCard1 blogData={blogData} pageData={module} />
                )}
              {/* {module.strapi_component === "page-modules.global-module" && module.select_module === "latest_news_slider" && <FeaturedSlider />}

                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && <TileBlockReview
                            reviewtext={"“Imagine if the clothing company Boden diversified into property sales - you'd expect colourful scatter cushions and a chocolate labrador dozing by a fire. That's the welcoming look radiated by Mr and Mrs Clarke.”"}
                            reviewauthor={"Aaron grey, seller"}
                            // reviewImg={ReviewImg}
                            tag="home-page"
                        />} */}
            </div>
          )
        })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  const _siteData = data?.strapiSiteConfig

  // var schemaSameAs = ''
  // if (siteData?.facebook_link.length > 1) {
  //   schemaSameAs = siteData?.facebook_link + ','
  // }
  // if (siteData?.twitter_link.length > 1) {
  //   schemaSameAs += siteData?.twitter_link + ','
  // }
  // if (siteData?.instagram_link.length > 1) {
  //   schemaSameAs += siteData?.instagram_link + ','
  // }
  // if (siteData?.linkedin_link.length > 1) {
  //   schemaSameAs += siteData?.linkedin_link + ','
  // }

  // var ldJson = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   "url": process.env.GATSBY_SITE_URL,
  //   "name": process.env.GATSBY_SITE_NAME,
  //   "alternateName": process.env.GATSBY_SITE_NAME,
  //   "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
  //   "contactPoint": {
  //     "@type": "ContactPoint",
  //     "telephone": siteData?.mobile_device_phone,
  //     "areaServed": process.env.GATSBY_DEFAULT_AREA,
  //     "contactType": "Sales"
  //   },
  //   "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  // };

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Baytify",
    alternateName: "Real Estate Agents in Dubai",
    url: "https://www.baytify.com/",
    logo: "https://ggfx-baytify.s3.eu-west-2.amazonaws.com/i.prod/baytify_email_logo_5da2bf6ad8.png",
    contactPoint: {
      "@type": "ContactPoint",
      areaServed: "Dubai",
    },
    sameAs: [
      "https://www.linkedin.com/company/baytify/",
      "https://www.instagram.com/baytify/",
    ],
  }

  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        /> 
    </SEO>
  )
}

export default HomePageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_STAT_WITH_CONTENT {
          id
          title
          strapi_component
          stat_with_data {
            stats_description
            stats_title
          }
          content {
            data {
              content
            }
          }
          cta_btn {
            link_type
            cta_label
            cta_link {
              slug
            }
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_COMMUNITY {
          id
          title
          strapi_component
          add_items {
            strapi_id
            title
            slug
            imagetransforms {
              popular_section_Transforms
            }
            description {
              data {
                description
              }
            }
            tail_image {
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PROPERTY_SERVICES {
          id
          strapi_component
          services {
            id
            service_link {
              cta_label
              strapi_id
              link_type
              cta_link {
                slug
                link_type
                external_link
                strapi_parent {
                  slug
                }
              }
            }
            add_image {
              url
              alternativeText
            }
            heading
            description {
              data {
                description
              }
            }
          }
          primary_title
          secondary_title
        }
        ... on STRAPI__COMPONENT_COMPONENTS_MARKET_BANNER {
          ...MarketBannerFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
          ...ImageAndContentFragment
        }
      }
    }

    allStrapiBlog(
      limit: 10
      filter: {publish: {eq: true}, date: {ne: null}}
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          publish
          strapi_id
          slug
          publish
          tile_image {
            url
          }
          date
        }
      }
    }
  }
`
